@import "./colors.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    @apply font-body text-base text-text-body;
  }

  a {
    text-decoration: none;
  }

  p {
    margin-bottom: 1rem;
    line-height: 160%;
  }

  p:last-child {
    margin-bottom: 0;
  }
  :focus {
    outline: none;
  }
}

input {
  @apply outline-none;
}

input {
  @apply outline-none;
}
